import React from "react";
import Ventaseurostienda from "./VentasEurosTienda";
import DataGridVentasClientes from "./DataGridVentasClientes";
import Clientestienda from "./ClientesTienda";
import { Box, Grid, Paper } from "@material-ui/core";
import AxiosApi from "./../../servicios/axios.service";
import { DateTime } from "luxon";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import CargandoDatos from "../Auxiliares/CargandoDatos";
// Cogemos los datos una vez y se los pasamos a los componenentes
var fechafin = DateTime.now().toFormat("yyyyMMdd");
var fechainicio = DateTime.local().startOf("year").toFormat("yyyyMMdd");
var fechainicio = DateTime.local()
    .minus({ months: 50 })
    .startOf("month")
    .toFormat("yyyyMMdd");

function EstadoTiendas({ codalm }) {
    const [etiquetas, setEtiquetas] = React.useState([]);
    const [ventas, setVentas] = React.useState([]);
    const [clientes, setClientes] = React.useState([]);
    const [filas, setFilas] = React.useState([]);
    const [tienda, setTienda] = React.useState("");
    const [cargando, setCargando] = React.useState(true);
    React.useEffect(() => {
        const getDatos = async () => {
            setCargando(true);
            let et = [];
            let v = [];
            let c = [];
            let url = "https://empleados.diferente.es/api/" + "ventasmensuales";
            let data = {
                fechainicio: fechainicio,
                fechafin: fechafin,
                almacen: codalm,
            };
            AxiosApi.axiosApi
                .get(url, { params: data })
                .then((response) => {
                    // console.log(response.data)

                    response.data.datos.map((e) => {
                        et.push(e.Mesano);
                        v.push(e.ventas);
                        c.push(e.clientes);
                    });
                    setTienda(response.data.datos[0].tienda);
                    setClientes(c);
                    setEtiquetas(et);
                    setVentas(v);
                    setFilas(response.data.datos);
                    setCargando(false);
                })
                .catch((error) => {
                    setCargando(false);
                    console.log(error);
                });
        };
        getDatos();
    }, []);
    return (
        <Grid sx={{ display: "flex", alignContent: "center", width: "99%" }}>
            {!cargando ? (
                <Grid
                    spacing={2}
                    // sx={{ p: 5 }}
                    alignContent="center"
                    alignItems="center"
                    container
                    // item
                    lg={12}
                >
                    {clientes.length > 0 &&
                    ventas.length > 0 &&
                    etiquetas.length > 0 &&
                    filas.length > 0 &&
                    tienda != "" ? (
                        <>
                            {/* <div>EstadoTiendas</div> */}
                            <Grid item xs={12} lg={4} md={6} xl={4}>
                                <Ventaseurostienda
                                    tienda={tienda}
                                    filas={filas}
                                    clientes={clientes}
                                    etiquetas={etiquetas}
                                    ventas={ventas}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={3}>
                                <DataGridVentasClientes
                                    codalm={codalm}
                                    tienda={tienda}
                                    filas={filas}
                                    clientes={clientes}
                                    etiquetas={etiquetas}
                                    ventas={ventas}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} xl={3}>
                                <Clientestienda
                                    tienda={tienda}
                                    filas={filas}
                                    clientes={clientes}
                                    etiquetas={etiquetas}
                                    ventas={ventas}
                                />
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
            ) : (
                <>
                    <CargandoDatos cargando={cargando} />
                    {/* <LinearProgress size={'13rem'}/>
          <CircularProgress size={'13rem'}/> */}
                </>
            )}
        </Grid>
    );
}

export default EstadoTiendas;
