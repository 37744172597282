

export const colores = [
  { Congelados: "rgba(51, 102, 204, 0.35)" },
  { Charcuteria: "rgba(204, 51, 102, 0.35)" },
  { Panaderia: "rgba(255, 204, 0, 0.35)" },
  { Carniceria: "rgba(204, 102, 51, 0.35)" },
  { Cocinados: "rgba(51, 204, 153, 0.35)" },
  { Planta: "rgba(255, 102, 0, 0.35)" },
  { Bodega: "rgba(204, 153, 102, 0.35)" },
  { Auxiliar: "rgba(204, 51, 204, 0.35)" },
  { Cafeteria: "rgba(204, 153, 204, 0.35)" },
];

export const conditionalRowStyles = colores.map((color) => {
  const tipo = Object.keys(color)[0];
  const valor = color[tipo];
  return {
    when: (row) => row.centrocoste === tipo,
    style: () => ({ backgroundColor: valor }),
  };
});

export const customStyles = {
  table: {
    style: {
      backgroundColor: "transparent", // Establece el fondo de las celdas como transparente

    },
  },
  head: {
    style: {
      backgroundColor: "#fff", // Establece el fondo de las celdas como transparente
    },
  },
  headCells:{
    style: {
      backgroundColor: "transparent", // Establece el fondo de las celdas como transparente
      paddingLeft: '0.5rem',
			paddingRight: '0.5rem',
      // display: 'flex',
			// alignItems: 'center',
			// justifyContent: 'center',
    },

  },
  subHeader: {
    style: {
      // backgroundColor: "transparent", // Establece el fondo de las celdas como transparente
    },
  },
  headRow: {
    style: {
      backgroundColor: "transparent", // Establece el fondo de las celdas como transparente
// padding:6,
    },
  },
  rows: {
    style: {
      backgroundColor: "transparent", // Establece el fondo de las filas como transparente
    },
  },
  // headCells: {
  //   style: {
  //     backgroundColor: 'transparent', // Establece el fondo de las celdas de encabezado como transparente
  //   },
  // },

  cells: {
    style: {
      padding:'2',
      flex:'unset',
      backgroundColor: "transparent", // Establece el fondo de las celdas como transparente
    },
  },
  pagination: {
    style: {
      backgroundColor: "transparent",
    },
  },
};
export function formatearNumero(numero) {
  // Formatear el número a dos decimales
  numero = parseFloat(numero);
  let numeroFormateado = numero.toFixed(2);
if (isNaN(numeroFormateado)){
  return 0
}
  // Verificar si los dos decimales son "00"
  if (numeroFormateado.slice(-2) === "00") {
    // Si los dos decimales son "00", mostrar el número como entero
    return parseInt(numeroFormateado);
  } else {
    // Si los dos decimales no son "00", mostrar el número con los decimales
    return parseFloat(numeroFormateado).toFixed(2);
  }
}
export function checkDigitEAN13(barcode) {
  const sum = barcode
    .split("")
    .map((n, i) => n * (i % 2 ? 3 : 1)) // alternate between multiplying with 3 and 1
    .reduce((sum, n) => sum + n, 0); // sum all values

  const roundedUp = Math.ceil(sum / 10) * 10; // round sum to nearest 10

  const checkDigit = roundedUp - sum; // subtract round to sum = check digit

  return barcode.toString() + checkDigit.toString();
}

export function sumarClavesNumericas(arrayObjetos) {
  let sumaTotal = 0;
  const totales = {};
  for (const objeto of arrayObjetos) {
    for (const clave in objeto) {
      // Convertir la clave a un número
      console.log(objeto[clave]);
      const valorNumerico = Number(objeto[clave]);
      // Si la clave es un número, sumarla al total
      if (!isNaN(valorNumerico)) {
        sumaTotal += Number(objeto[clave]);
        totales[clave] = (totales[clave] || 0) + Number(objeto[clave]);
      } else {
        totales[clave] = "";
      }
    }
  }
  totales["Familia"] = "sumaTotal";
  arrayObjetos.push(totales);
  return arrayObjetos;
}
export function sumarElementos(arrayNumeros) {
  const suma = arrayNumeros.reduce((acumulador, valorActual) => acumulador + valorActual, 0);
  return suma

}
export function sumarElementosEuros(arrayNumeros) {
  const suma = arrayNumeros.reduce((acumulador, valorActual) => acumulador + valorActual, 0);
  return suma.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })
}


export function sumarClavesNumericasExistencias(arrayObjetos) {
  let copiaarray=arrayObjetos
  // console.log(copiaarray)
  let sumaTotal = 0;
  const totales = {};
  for (const objeto of copiaarray) {
    for (const clave in objeto) {
      // Convertir la clave a un número
      // console.log(objeto[clave]);
      const valorNumerico = Number(objeto[clave]);
      // Si la clave es un número, sumarla al total
      if (!isNaN(valorNumerico)) {
        sumaTotal += Number(objeto[clave]);
        totales[clave] = (totales[clave] || 0) + Number(objeto[clave]);
      } else {
        totales[clave] = "";
      }
    }
  }
  totales["codalm"] = "Total";
  copiaarray.push(totales);
  return copiaarray;
}
export function calcularVariacionPorcentual(valorInicial, valorFinal) {
  if (valorFinal === 0) {
    // throw new Error("No se puede calcular la variación porcentual si el valor final es 0");
    return 0
  }

  const diferencia = valorInicial - valorFinal;
  const variacionPorcentual = (diferencia / valorFinal) ;
  return variacionPorcentual.toLocaleString('es-ES', { style: 'percent',minimumFractionDigits:2})
};

export function minimoArray(array) {
  console.log(array)
  if (array.length === 0) {
    return undefined; // Si el array está vacío, devolver undefined
  }

  let minimo = array[0]; // Asignar el primer elemento del array como el mínimo inicialmente

  for (let i = 1; i < array.length; i++) {
    if (array[i] < minimo) {
      minimo = array[i]; // Actualizar el mínimo si se encuentra un valor menor
    }
  }

  return minimo; // Devolver el valor mínimo encontrado
}
