// En un archivo context.js
import React, { createContext, useContext, useState } from "react";
import AuthServive from './servicios/auth.service'
const MiContexto = createContext();

export const MiContextoProvider = ({ children }) => {
    const [miEstado, setMiEstado] = useState(AuthServive.getObservaciones());

    return (
        <MiContexto.Provider value={{ miEstado, setMiEstado }}>
            {children}
        </MiContexto.Provider>
    );
};

export const useMiContexto = () => useContext(MiContexto);
